/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Tab, Tabs } from 'react-bootstrap';
import { Grid, Capslock, Search, Lightning } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';

import AllGames from 'src/conponents/Home/AllGames';
import { DEFAULT_CONFIGS } from 'src/constants';
import useSearchQueryParams from 'src/hooks/useSearchQueryParams';

import './index.scss';
import useTranslate from 'src/hooks/useTranslate';
import { useLanguageContext } from 'src/context/languageContext';
// import { setHeaderLocale } from 'src/helpers/request';

function HomeContainer() {
  const { i18n } = useLanguageContext();

  const [searchTerm, setSearchTerm] = useState('');
  const [originalData, setOriginalData] = useState([]);
  const [gamesData, setGamesData] = useState([]);

  // const groupCode = useSearchQueryParams(
  //   'groupCode',
  //   DEFAULT_CONFIGS.groupCode,
  // );

  const oc = useSearchQueryParams('oc', DEFAULT_CONFIGS.brandCode);
  const t = useSearchQueryParams('t', DEFAULT_CONFIGS.playerToken);

  const l = useSearchQueryParams('l', DEFAULT_CONFIGS.language);

  const values = useSearchQueryParams();

  const [sortBy, setSortBy] = useState(values.get('sortBy'));
  const handleSearchGame = (e) => {
    setSearchTerm(e.target.value);
  };
  const history = useHistory();

  const translate = useTranslate();

  useEffect(() => {
    if (searchTerm !== '') {
      setGamesData(
        originalData.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setGamesData(originalData);
    }
  }, [searchTerm]);

  const debouncedResults = React.useMemo(
    () => debounce(handleSearchGame, 300),
    [],
  );

  useEffect(() => () => {
    debouncedResults.cancel();
  });

  const fetchData = async (params) => {
    setLoading(false);

    // const data = await listGames({
    //   ...params,
    //   playerToken,
    //   groupCode,
    //   brandCode,
    // });
    const response = await fetch(
      `/api/v1/available-games?${new URLSearchParams(params)}`,
    );
    const data = await response.json();

    if (data) {
      const games = data.data?.filter((x) => x.status === 'ACTIVE');
      setGamesData(games);
      setOriginalData(games);
    } else {
      setGamesData([]);
      setOriginalData([]);
    }
  };

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    switch (sortBy) {
      case 'playCount':
        setFilter('playCount');
        break;
      case 'created':
        setFilter('created');
        break;
      default:
        setFilter('all');
        break;
    }
    const params =
      sortBy && ['created', 'playCount'].includes(sortBy)
        ? {
            sortBy,
          }
        : {};

    if (i18n?.locale) {
      // setHeaderLocale(i18n.locale);
      fetchData({
        ...params,
        oc,
        t,
        l,
      });
    } else {
      setLoading(false);
    }
  }, [sortBy, i18n?.locale]);

  const handleFilter = (key) => {
    if (key === 'all') {
      values.delete('sortBy');
      setSortBy('');
    } else {
      setSortBy(key);
      values.set('sortBy', key);
    }
    const url = `${window.location.pathname}?${values.toString()}`;
    history.push(url);
  };

  return (
    <div className={`home-page__wrapper ${(loading && '--loading') || ''}`}>
      {!loading && (
        <>
          <div className="search-container">
            <div className="search-icon">
              <Search />
            </div>
            <input
              type="text"
              className="form-control"
              placeholder={translate('common.text.search', 'Search')}
              onChange={debouncedResults}
            />
          </div>
          <div className="home-page__tabs-container">
            <Tabs
              className="mb-xxl-5 mb-4"
              variant="pills"
              activeKey={filter}
              onSelect={(e) => handleFilter(e)}
            >
              <Tab
                eventKey="all"
                className="home-page__tabs-item"
                title={
                  <div className="home-page__tabs-item__title">
                    <Grid size={14} />
                    <span>
                      {translate('common.text.allGames', 'All Games')}
                    </span>
                  </div>
                }
              >
                <AllGames games={gamesData} />
              </Tab>
              <Tab
                className="home-page__tabs-item"
                eventKey="playCount"
                title={
                  <div className="home-page__tabs-item__title">
                    <Lightning size={14} />
                    <span> {translate('common.text.popular', 'Popular')}</span>
                  </div>
                }
              >
                <AllGames games={gamesData} />
              </Tab>
              <Tab
                eventKey="created"
                title={
                  <div className="home-page__tabs-item__title">
                    <Capslock size={14} />
                    <span> {translate('common.text.latest', 'Latest')}</span>
                  </div>
                }
              >
                <AllGames games={gamesData} />
              </Tab>
            </Tabs>
          </div>
        </>
      )}
      {loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
}

export default HomeContainer;
